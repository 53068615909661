<template>
  <div>
    <Navbar v-bind:isLetsTalk="true" />
    <Background />
    <!-- Modal -->
    <div
      class="modal fade"
      id="ModalSubmitLetstalk"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body" style="padding:2em">
            <div class="row d-flex justify-content-center">
              <div class="modal__send-icon">
                <svg
                  width="300.834"
                  height="166.143"
                  viewBox="0 0 300.834 166.143"
                >
                  <g transform="translate(0 0.002)">
                    <path
                      style="fill: #f7d194;"
                      d="M0,166.174,234.887.05,13.435,111.9,1.222,162.063"
                      transform="translate(65.947 -0.033)"
                    />
                    <path
                      style="fill: #ffc65a;"
                      d="M221.452.05,56.99,148.083,0,111.9Z"
                      transform="translate(79.383 -0.033)"
                    />
                    <path
                      style="fill: #ba8a43;"
                      d="M248.319.017C241.813-1.626,0,104.464,0,104.464l13.432,61.678,13.435-54.277Z"
                      transform="translate(52.516 0)"
                    />
                    <path
                      style="fill: #ffc65a;"
                      d="M52.512,104.5,0,72.422,300.834.05Z"
                      transform="translate(0 -0.033)"
                    />
                  </g>
                </svg>
              </div>
              <div class="modal__title">
                <span>YOUR FORM HAS BEEN SUCCESFULLY SUBMITTED!</span>
              </div>
              <div class="modal__text">
                <span> We will reply as soon as possible!</span>
              </div>
              <div class="pt-5">
                <a class="modal_btn-send" href="/">Back to home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-aos="zoom-out" data-aos-duration="1000" class="letstalk___">
      <div class="row form-content">
        <div
          class="col-md-6 d-flex justify-content-center letsTalk__fields_box"
        >
          <div class="letsTalk__fields">
            <form @submit.prevent="Submit" enctype="multipart/form-data">
              <div class="form-group">
                <input
                  v-model="FullName"
                  @change="FullNameOnchange"
                  id="FullName"
                  class="form-control form-control-lg field__"
                  type="text"
                  placeholder="Full name *"
                />
                <span id="validation-error-span" class="fa"></span>
              </div>
              <div class="form-group">
                <input
                  v-model="Email"
                  @change="EmailNameOnchange"
                  id="Email"
                  class="form-control form-control-lg field__"
                  type="email"
                  placeholder="Email *"
                />
                <span id="validation-error-span" class="fa"></span>
              </div>
              <div class="form-group">
                <textarea
                  v-model="Message"
                  @change="MessageOnchange"
                  id="Message"
                  class="form-control form-control-lg field__"
                  rows="5"
                  placeholder="Message *"
                />
                <span id="validation-error-span" class="fa"></span>
              </div>
              <div class="form-group">
                <input
                  class="form-control-file field__file"
                  @change="AttachmentOnchange"
                  type="file"
                  id="Attachment"
                  name="Attachment"
                />
                <span id="validation-error-span" class="fa"></span>
              </div>
              <div class="form-group">
                <button type="submit" class="btn_lets__talk pull-right">
                  <span class="default">Send</span>
                  <span class="success">Sent</span>
                  <div class="left"></div>
                  <div class="right"></div>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-5 d-flex justify-content-start letsTalk__info_box">
          <div class="letsTalk__info">
            <div class="--letsTalk__text">
              <div data-depth="0.4">
                <div class="letsTalk__title">
                  <svg
                    id="letsTalk__title__1"
                    style="position:absolute"
                    xmlns="http://www.w3.org/2000/svg"
                    width="623.881"
                    height="524.606"
                    viewBox="0 0 623.881 524.606"
                  >
                    <g transform="translate(-314.67 -527.92)">
                      <g transform="translate(314.67 623.195)">
                        <path
                          style="fill: #212121;"
                          d="M511.161,1291.4H317.377a2.707,2.707,0,1,1,0-5.414H511.161a2.707,2.707,0,1,1,0,5.414Z"
                          transform="translate(-314.67 -1285.985)"
                        />
                      </g>
                      <g transform="translate(459.738 643.764)">
                        <path
                          style="fill: #212121;"
                          d="M1714.127,1455.059h-242.5a2.707,2.707,0,1,1,0-5.414h242.5a2.707,2.707,0,1,1,0,5.414Z"
                          transform="translate(-1468.92 -1449.645)"
                        />
                      </g>
                      <g
                        style="isolation: isolate;"
                        transform="translate(324.389 527.92)"
                      >
                        <g style="isolation: isolate;" transform="translate(0)">
                          <path
                            style="fill: #212121;"
                            d="M392,559h12.63v44.278h27.361v10.29H392Z"
                            transform="translate(-392 -555.094)"
                          />
                          <path
                            style="fill: #212121;"
                            d="M808.85,603.427v10.134H766.6V559h41.236v10.134H779.149v11.849H804.48V590.8H779.149v12.63Z"
                            transform="translate(-719.52 -555.094)"
                          />
                          <path
                            style="fill: #212121;"
                            d="M1151.192,569.283H1133.73V559h47.552v10.29H1163.82v44.278h-12.627Z"
                            transform="translate(-1040.508 -555.094)"
                          />
                          <path
                            style="fill: #212121;"
                            d="M1566.138,535.407c0,2.026-.312,3.508-2.026,7.792l-4.525,11.225h-7.718l3.353-12.317a6.92,6.92,0,0,1-4.132-6.7,7.524,7.524,0,0,1,15.048,0Z"
                            transform="translate(-1405.414 -527.92)"
                          />
                          <path
                            style="fill: #212121;"
                            d="M1715.46,601.633l4.287-9.509a32.1,32.1,0,0,0,18.006,5.69c7.483,0,10.525-2.493,10.525-5.847,0-10.212-31.727-3.2-31.727-23.464,0-9.277,7.483-16.993,23-16.993,6.86,0,13.875,1.634,18.943,4.834l-3.9,9.587a30.824,30.824,0,0,0-15.122-4.287c-7.483,0-10.367,2.806-10.367,6.236,0,10.055,31.727,3.118,31.727,23.152,0,9.121-7.562,16.917-23.153,16.917C1729.024,607.949,1720.292,605.376,1715.46,601.633Z"
                            transform="translate(-1549.125 -548.545)"
                          />
                        </g>
                      </g>
                      <g transform="translate(675.751 778.796)">
                        <line
                          style="fill: #fff;"
                          x2="41.007"
                          y2="268.053"
                          transform="translate(0.947 0.971)"
                        />
                        <path
                          style="fill: #231f20;"
                          d="M3229.6,2794a.944.944,0,0,1-.931-.8l-41.006-268.052a.943.943,0,1,1,1.863-.285l41.008,268.052a.942.942,0,0,1-.789,1.075A.908.908,0,0,1,3229.6,2794Z"
                          transform="translate(-3187.645 -2524.038)"
                        />
                        <line
                          style="fill: #fff;"
                          x2="41.006"
                          y2="268.053"
                          transform="translate(53.242 0.971)"
                        />
                        <path
                          style="fill: #231f20;"
                          d="M3645.711,2794.2a.943.943,0,0,1-.93-.8l-41.008-268.052a.943.943,0,0,1,1.864-.285l41.012,268.052a.945.945,0,0,1-.934,1.086Z"
                          transform="translate(-3551.464 -2524.234)"
                        />
                        <path
                          style="fill: #231f20;"
                          d="M3546.977,4526.755h-52.294a.943.943,0,1,1,0-1.885h52.294a.943.943,0,1,1,0,1.885Z"
                          transform="translate(-3455.27 -4273.401)"
                        />
                        <path
                          style="fill: #231f20;"
                          d="M3507.856,4271.026h-52.294a.943.943,0,0,1,0-1.886h52.294a.943.943,0,0,1,0,1.886Z"
                          transform="translate(-3421.066 -4049.812)"
                        />
                        <path
                          style="fill: #231f20;"
                          d="M3467.127,4004.825h-52.295a.943.943,0,1,1,0-1.885h52.295a.943.943,0,1,1,0,1.885Z"
                          transform="translate(-3385.455 -3817.069)"
                        />
                        <path
                          style="fill: #231f20;"
                          d="M3428.906,3753.035h-52.294a.943.943,0,1,1,0-1.885h52.294a.943.943,0,0,1,0,1.885Z"
                          transform="translate(-3352.039 -3596.925)"
                        />
                        <path
                          style="fill: #231f20;"
                          d="M3389.787,3497.305h-52.294a.943.943,0,0,1,0-1.885h52.294a.943.943,0,0,1,0,1.885Z"
                          transform="translate(-3317.836 -3373.335)"
                        />
                        <path
                          style="fill: #231f20;"
                          d="M3349.058,3231.105h-52.3a.943.943,0,0,1,0-1.885h52.3a.943.943,0,0,1,0,1.885Z"
                          transform="translate(-3282.225 -3140.591)"
                        />
                        <path
                          style="fill: #231f20;"
                          d="M3303.828,2944.1h-52.295a.943.943,0,1,1,0-1.885h52.295a.943.943,0,0,1,0,1.885Z"
                          transform="translate(-3242.679 -2889.662)"
                        />
                        <path
                          style="fill: #231f20;"
                          d="M3263.108,2677.885h-52.3a.943.943,0,0,1,0-1.885h52.3a.943.943,0,0,1,0,1.885Z"
                          transform="translate(-3207.077 -2656.901)"
                        />
                      </g>
                      <g transform="translate(728.514 780.27)">
                        <path
                          style="fill: #262626;"
                          d="M3728.436,3705.9s-1.52,1.634-1.307,3.268,12.032,2.123,13.872,1.487-1.061-6.3-1.061-6.3Z"
                          transform="translate(-3712.071 -3557.489)"
                        />
                        <path
                          style="fill: #525354;"
                          d="M3630.654,2536.95s10.192,32.573,4.247,40.25,1.862,106.225,1.862,106.225-13.078-.423-15.627-1.306-21.021-145.811-10.419-146.157A128.862,128.862,0,0,1,3630.654,2536.95Z"
                          transform="translate(-3607.461 -2535.887)"
                        />
                        <path
                          style="fill: #353535;"
                          d="M3724.942,2536.828s11.407,24.7,3.439,42.117c-1.425,21.378,1.418,89.386,2.669,104.357-6.108,1.667-14.569,2.3-15.627-1.306s5.134-1.274,7.893-10.055-.832-90.857-.06-94.87c4.376-22.757-8.812-30.906-7.228-41.073C3719.354,2535.783,3721.085,2535.337,3724.942,2536.828Z"
                          transform="translate(-3701.749 -2535.765)"
                        />
                      </g>
                      <g transform="translate(689.682 763.255)">
                        <path
                          style="fill: #262626;"
                          d="M3361.839,3194.322s-5.507,3.99-5.443,6.944c.018.826,4.453,4.389,14.22,2.124s6.852-10.85,6.852-10.85S3361.839,3192.9,3361.839,3194.322Z"
                          transform="translate(-3349.118 -3092.981)"
                        />
                        <path
                          style="fill: #525354;"
                          d="M3352.544,2407.952s-13.591,34.478-31,48.066,5.3,43.93,5.3,43.93l-12.926,1.782-5.462-.912s-13.659-32.06-8.989-42.676,22.083-55.2,28.452-57.752C3338.953,2403.767,3339.807,2406.962,3352.544,2407.952Z"
                          transform="translate(-3298.488 -2400.39)"
                        />
                        <path
                          style="fill: #353535;"
                          d="M3418.826,2460.56s-40.131,35.54-41.4,42.547c-1.024,5.631-7.9,38.936,2.772,51.232,4.659.754,11.029.082,14.426-2.823-11.041-17.25-15.5-28.663-11.678-34.82S3418.191,2480.649,3418.826,2460.56Z"
                          transform="translate(-3364.77 -2452.998)"
                        />
                      </g>
                      <path
                        style="fill: #c67a60;"
                        d="M3288.955,1182.594s-4.072.268-5.047-10.885-7.594-15.194-10.253-12.282,2.24,9.324.787,11,4.865,9.735,4.865,9.735S3290.448,1186.8,3288.955,1182.594Z"
                        transform="translate(-2586.389 -551.339)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M3272.14,1165.78s7.561,5.083,6.433,8.851,1.555,11.6,1.555,11.6-3.743-6.752-4.571-8.592-1.053-1.517-2.334-1C3272.082,1170.345,3269.772,1167.4,3272.14,1165.78Z"
                        transform="translate(-2584.871 -557.693)"
                      />
                      <path
                        style="fill: #8d2b26;"
                        d="M3331.311,1356.015s8.063-3.682-5.208-25.839-23.693-34.814-23.693-34.814,3.953-18.162,16.556-4.382,40.462,53.593,36.1,69.7S3366.822,1402.607,3331.311,1356.015Z"
                        transform="translate(-2612.236 -662.643)"
                      />
                      <g
                        style="isolation: isolate;"
                        transform="translate(569.355 537.133)"
                      >
                        <g
                          style="isolation: isolate;"
                          transform="translate(0 0)"
                        >
                          <path
                            style="fill: #212121;"
                            d="M2359.125,615.48l-18.025-3.5,2.09-10.76,49.087,9.534-2.089,10.76-18.026-3.5-8.993,46.3-13.033-2.532Z"
                            transform="translate(-2341.1 -601.22)"
                          />
                          <path
                            style="fill: #212121;"
                            d="M2681.4,759.5l-26.154-5.08-7.364,11.259-13.359-2.6,36.19-52.182,12.875,2.5,14.106,61.95-13.681-2.657Zm-2.157-10.822-4.693-23.58L2661.372,745.2Z"
                            transform="translate(-2597.642 -697.115)"
                          />
                          <path
                            style="fill: #212121;"
                            d="M3194.989,778.8l13.037,2.539-8.992,46.3,28.248,5.482-2.09,10.76-41.281-8.018Z"
                            transform="translate(-3077.984 -756.481)"
                          />
                        </g>
                      </g>
                      <path
                        style="fill: #edd3d6;"
                        d="M3438.89,2362.43l-7.26,17.667s20.022,17.017,28.979,17.083,30.189.466,16.317-11.907S3438.89,2362.43,3438.89,2362.43Z"
                        transform="translate(-2725.215 -1603.945)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M3461.422,1345.8c-7.562,1.287-17.4-1.84-14.424,9.625s3.89,19.846,6.7,22.194c7.548,3,8.336,6.579,18.569.88C3478.775,1355.992,3464.273,1345.315,3461.422,1345.8Z"
                        transform="translate(-2738.175 -715.074)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M3493,1582.726a8.424,8.424,0,0,1,7.82,4.575C3506.166,1589.539,3493.924,1586.9,3493,1582.726Z"
                        transform="translate(-2778.872 -922.228)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M3515.648,1605.336a1.7,1.7,0,0,1-.2,1.821,15.673,15.673,0,0,1-26.828,1.18c-.319-.484-2.576,1.8-2.423,1.214,3.433-.442,8.043-4.661,8.672-8.412,3.227.564,6.17-.847,9.24-1.356,1.573-.26,1.518-2.137,2.757-.706C3508.138,1602.373,3512.183,1605.373,3515.648,1605.336Z"
                        transform="translate(-2772.918 -936.067)"
                      />
                      <path
                        style="fill: #1c1c1c;"
                        d="M3448.657,1288.721c.628,0,3.547,5.325,4.65,5.153,3.445-.534,8.272,8.142,11.688,6.553,4.322-2.011,1.993-5.052,7.068-5.547.724-.07-1.91-21.283-2.347-23.231-.444-1.977-1.429-3.91.1-5.71.823-.973.257-2.237-1.2-2.986a22.534,22.534,0,0,0-4.425-1.362c.524-1.09,1.653-.723,2.2-1.372.448-.529,1.055-.924.585-1.81-.391-.739-1.036-.754-1.692-.708a25.681,25.681,0,0,0-5.775,1.423,2.415,2.415,0,0,1-.009-.684c.415-.916,2.292-1.131,1.47-2.48-.733-1.206-2.089-.343-3.039-.126-4.846,1.109-9.657,2.336-12.769,6.5a2.831,2.831,0,0,0,.563,2.569C3448.309,1268.759,3444.074,1288.189,3448.657,1288.721Z"
                        transform="translate(-2736.934 -635.989)"
                      />
                      <path
                        style="fill: #262626;"
                        d="M3409.921,1323.915c-2.189-5.037,2.336-9.14,4.022-12.875.264.62.476,1.266.8,1.858,2.295,4.2,5.94,6.483,10.054,8.091a12.449,12.449,0,0,1-6.084-.145c-1.383-.285-1.687.251-1.619,1.408a10.555,10.555,0,0,1-.827,5.582,4.363,4.363,0,0,0-.172,3.659c.6,1.911,1.058,3.848,1.673,6.129-1.548-.806-1.235-2.044-2.056-2.608C3415.712,1335.014,3410.863,1326.084,3409.921,1323.915Z"
                        transform="translate(-2705.722 -684.696)"
                      />
                      <path
                        style="fill: #1c1c1c;"
                        d="M3643.768,1408.391c3.275-1.274-1.91-21.283-2.348-23.231"
                        transform="translate(-2908.638 -749.501)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M3454.475,1469c-1.988.173-1.8,5.05,1.9,8.888C3455.57,1473.138,3454.475,1469,3454.475,1469Z"
                        transform="translate(-2744.091 -822.803)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M2988.52,1125.022s4.919-1.582-.46-11.994,0-16.986,4.814-15.569,2.83,9.342,5.51,10.19,0,10.884,0,10.884S2989.233,1129.454,2988.52,1125.022Z"
                        transform="translate(-2335.432 -497.791)"
                      />
                      <path
                        style="fill: #8d2b26;"
                        d="M3421.85,1649.393c-4.193,2.529.143,7.322-.329,25.858-.719,28.222-5.011,67.994-5.011,67.994s19.575,22.437,49.506,14.956c11.563-20.808-1.648-112.576-10.473-113.947-5.509-.855-13.75-2.716-21.811-.93C3428.888,1644.4,3421.85,1649.393,3421.85,1649.393Z"
                        transform="translate(-2711.995 -974.564)"
                      />
                      <path
                        style="fill: #8d2b26;"
                        d="M3058.286,1318.114s-29.266,4.273-37.366-13.679a277.147,277.147,0,0,1-13.342-37.965s-13.343,1.821-10.96,15.444,14.49,48.147,28.926,53.28S3070.679,1360.161,3058.286,1318.114Z"
                        transform="translate(-2344.626 -645.728)"
                      />
                      <path
                        style="fill: #ea5546;"
                        d="M3575.4,1637.118c12.464,32.713,2.011,31-11.361,27.2s-11.287-27.587-11.287-27.587S3572.067,1632.665,3575.4,1637.118Z"
                        transform="translate(-2831.008 -967.968)"
                      />
                      <path
                        style="fill: #ad431e;"
                        d="M3611.976,1636.773c6.661,27.289,4.336,24.771-2.809,21.595s-9.287-21.921-9.287-21.921S3610.2,1633.058,3611.976,1636.773Z"
                        transform="translate(-2872.319 -967.969)"
                      />
                      <path
                        style="fill: #591c1b;"
                        d="M3057.234,1384.55s-24.438,1.494-40.251-18.149c-5.413-6.724-19.406-48.262-19.406-48.262s-3.155,5.806,0,15.192,10.845,37.624,21.062,44.617c13.388,9.156,23.955,12.485,31.759,12.535C3055.8,1390.515,3057.324,1388.839,3057.234,1384.55Z"
                        transform="translate(-2344.488 -690.904)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M3017.813,1098.79s-5.873,7.926-2.353,10.792,4.964,11.074,4.964,11.074.431-7.712.324-9.729.346-1.828,2.146-1.934C3020.544,1102.847,3021.523,1099.178,3017.813,1098.79Z"
                        transform="translate(-2360.367 -499.122)"
                      />
                      <path
                        style="fill: #474342;"
                        d="M4710.473,4531.58c.352,2.109,23.257,2.438,24.569,1.125s-3.562-23.445-3.562-23.445l-14.068.938S4709.158,4523.7,4710.473,4531.58Z"
                        transform="translate(-3843.206 -3480.958)"
                      />
                      <path
                        style="fill: #474342;"
                        d="M5141.925,4553.574c1.5-2.438-6-18.944-6-18.944l-11.628.938s-4.5,15.38.754,18.007c2.611,1.3,15.381,2.438,16.882,0"
                        transform="translate(-4203.573 -3503.139)"
                      />
                      <path
                        style="fill: #1d4368;"
                        d="M4728.255,3617.22s-5.615,111.692-5.466,118.7,22.53.568,22.53.568l9.915-78.928.977-38.74"
                        transform="translate(-3854.096 -2701.031)"
                      />
                      <path
                        style="fill: #234f82;"
                        d="M4950.26,3624.168c.315,4.19,1.435,20.716,3.858,38.9,0,0,14.9,76.183,17.771,78.85s14.786-3.259,14.786-3.259-1.76-75.052-7.608-115.145"
                        transform="translate(-4052.981 -2706.531)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M4650.434,2559.23s-4.072.267-5.048-10.885-7.593-15.194-10.253-12.283,2.24,9.325.787,11,4.867,9.735,4.867,9.735"
                        transform="translate(-3776.755 -1754.957)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M4641.578,2562.864s-3.742-6.752-4.571-8.591-1.054-1.517-2.333-1c-1.143-6.3-3.453-9.248-1.085-10.866,0,0,7.561,5.083,6.435,8.851s1.554,11.6,1.554,11.6"
                        transform="translate(-3775.211 -1761.305)"
                      />
                      <path
                        style="fill: #0c86a3;"
                        d="M4669.828,2688.082l11.563,28.213,22.875.772-24.357-33.547S4668.206,2684.141,4669.828,2688.082Z"
                        transform="translate(-3807.658 -1884.68)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M4856.648,2888.275l-2.7,1.006,2.778,9.63,23.245,3.61,1.3-13.426-6.851-1.944-15.114.594A8.588,8.588,0,0,0,4856.648,2888.275Z"
                        transform="translate(-3968.775 -2062.718)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M4845.053,2578.338c-7.667.243-16.984-4.2-15.6,7.565s1.144,20.192,3.6,22.9c7.067,4,7.359,7.657,18.276,3.4C4860.851,2590.8,4847.944,2578.252,4845.053,2578.338Z"
                        transform="translate(-3947.233 -1792.171)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M4850.34,2807.45a8.423,8.423,0,0,1,7.122,5.6C4862.451,2816,4850.686,2811.7,4850.34,2807.45Z"
                        transform="translate(-3965.619 -1993.034)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M4851.175,2851.728a1.7,1.7,0,0,1-.445,1.777,14.922,14.922,0,0,1-14.217,6.294c-5.71-.593-16.48-4.179-16.248-4.738,2.4-3.456,9.769-6.7,10.9-10.328,3.12,1.005,6.228,0,9.338-.081,1.595-.044,1.8-1.915,2.828-.324C4844.143,2847.763,4847.735,2851.292,4851.175,2851.728Z"
                        transform="translate(-3939.32 -2024.718)"
                      />
                      <path
                        style="fill: #1c1c1c;"
                        d="M4829.061,2524.11c.62.089,2.787,5.758,3.9,5.74,3.484-.058,7.082,9.2,10.683,8.089,4.556-1.4,2.665-4.732,7.76-4.525.726.029,1.014-21.344.847-23.334-.17-2.019-.88-4.068.88-5.643.948-.851.561-2.18-.783-3.122a22.575,22.575,0,0,0-4.2-1.954c.667-1.006,1.734-.49,2.368-1.06.514-.462,1.17-.77.826-1.713-.288-.784-.924-.88-1.58-.931a25.648,25.648,0,0,0-5.915.621c.042-.377.013-.568.084-.679.536-.851,2.426-.8,1.795-2.256-.561-1.3-2.022-.629-2.993-.541-4.952.438-9.886,1-13.537,4.692a2.824,2.824,0,0,0,.207,2.62C4831.445,2504.285,4824.593,2522.955,4829.061,2524.11Z"
                        transform="translate(-3945.663 -1717.714)"
                      />
                      <path
                        style="fill: #262626;"
                        d="M4793.328,2544.033c-1.481-5.289,3.562-8.735,5.747-12.2a20.2,20.2,0,0,0,.538,1.948c1.7,4.472,5,7.234,8.852,9.39a12.463,12.463,0,0,1-6.008-.974c-1.331-.473-1.705.02-1.8,1.174a10.513,10.513,0,0,1-1.583,5.416,4.356,4.356,0,0,0-.668,3.6c.328,1.974.521,3.957.818,6.3-1.423-1.005-.944-2.193-1.679-2.864C4797.549,2555.82,4793.964,2546.312,4793.328,2544.033Z"
                        transform="translate(-3915.537 -1752.055)"
                      />
                      <path
                        style="fill: #1c1c1c;"
                        d="M5017.61,2655.3c3.418-.814,1.014-21.344.847-23.334"
                        transform="translate(-4111.866 -1839.609)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M4825.292,2690.689c-1.994-.1-2.473,4.756.672,9.064C4825.81,2694.932,4825.292,2690.689,4825.292,2690.689Z"
                        transform="translate(-3942.327 -1890.948)"
                      />
                      <path
                        style="fill: #00a8db;"
                        d="M4723.347,2912.91c5.225,2.355,7.046,4.769,12.2,7.25,2.838,1.366-3.751,50.667-3.124,53.365-1.208,20.135,2.993,27.967,3.429,31.11-13.255,4.714-26.086,5.279-52.13,1.15-5.84-.925-4.976-57.173-5.936-64.109-1.76-12.714-7.152-12.191-6.876-14.093.665-4.038,2.421-7.135,6.867-8.771,4.328-1.594,8.406-3.784,12.593-5.714,1.214.386,1.548,1.487,2.157,2.356,2.3,3.288,5.05,6.14,9.109,7.481,7.609,2.514,15.567.907,19.9-7.164C4722.065,2914.785,4722.2,2913.559,4723.347,2912.91Z"
                        transform="translate(-3808.731 -2085.24)"
                      />
                      <path
                        style="fill: #00a8db;"
                        d="M4831.558,2893.643c.345-.238.7-.462,1.036-.715,1.365-1.031,12.71,2.124,23.822-1.769a13.966,13.966,0,0,1,5.844,1.971c.309.165.595.377.89.564-1.606,5.647-4.738,10.107-10.592,11.688a16.081,16.081,0,0,1-15.943-3.826,22.3,22.3,0,0,1-4.688-5.989C4831.616,2894.969,4831,2894.414,4831.558,2893.643Z"
                        transform="translate(-3949 -2066.223)"
                      />
                      <path
                        style="fill: #065c70;"
                        d="M4826.7,2910.92c3.99,4.493,17.129,5.384,26.268,2.609,2.729-.828,3.453-.991,4.855-2.206.148-.125.31-.251.467-.377l.464.082c-1.987,2.3-4.989,3.51-11.165,4.139-5.919.6-11.311.86-15.967-.852a22.319,22.319,0,0,1-5.844-3.2A1.8,1.8,0,0,1,4826.7,2910.92Z"
                        transform="translate(-3944.146 -2083.5)"
                      />
                      <path
                        style="fill: #212121;"
                        d="M4082.22,2279.077l-3.693,10.268,5.792,12.991-11.814,5.269-22.906-51.376,11.814-5.269,10.7,24.005,12.052-34.144,13.212-5.891L4086.3,2267.32l35.229,18.431-13.871,6.185Z"
                        transform="translate(-3265.517 -1492.47)"
                      />
                      <path
                        style="fill: #212121;"
                        d="M4463.61,2177.457l13.651-6.087,12.679,34.563-9.1,4.057Zm19.969,45.382c-1.668-3.743.023-7.841,4.134-9.677s8.288-.352,9.958,3.393a7.726,7.726,0,0,1-14.092,6.284Z"
                        transform="translate(-3627.494 -1436.898)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M4391.829,2596.645s4.919-1.581-.46-11.993,0-16.986,4.812-15.571,2.832,9.342,5.512,10.191,0,10.884,0,10.884-9.145,10.921-9.864,6.488"
                        transform="translate(-3562.371 -1784.458)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M4423.693,2592.277s.431-7.712.324-9.729.345-1.829,2.146-1.934c-2.346-6.146-1.367-9.815-5.083-10.2,0,0-5.872,7.927-2.352,10.794s4.964,11.073,4.964,11.073"
                        transform="translate(-3587.27 -1785.786)"
                      />
                      <path
                        style="fill: #0c86a3;"
                        d="M4383.31,2722.82s14.544,40.129,16.879,42.039,26.248,14.011,26.248,14.011-25.927-1.494-32.653-10.047S4383.31,2722.82,4383.31,2722.82Z"
                        transform="translate(-3557.286 -1919.041)"
                      />
                      <path
                        style="fill: #00a8db;"
                        d="M4426.437,2776.243s-27.31-7.217-30.707-16.134-12.42-39.917-12.42-39.917,10.19-.85,13.587,0,10.617,31,10.617,31,11.939-.85,18.922,1.273S4426.437,2776.243,4426.437,2776.243Z"
                        transform="translate(-3557.286 -1916.414)"
                      />
                    </g>
                  </svg>
                  <svg
                    id="letsTalk__title__2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="439"
                    height="88"
                    viewBox="0 0 439 88"
                  >
                    <text
                      style="fill: #212121;
                    font-size: 72px;
                    font-family: Montserrat-Bold, Montserrat;
                    font-weight: 700;"
                      transform="translate(0 70)"
                    >
                      <tspan x="0" y="0">LET’S TALK!</tspan>
                    </text>
                  </svg>
                </div>
                <div class="letsTalk__box_lines">
                  <div class="box__line_1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="184"
                      height="5"
                      viewBox="0 0 184 5"
                    >
                      <line x2="179" transform="translate(2.5 2.5)" />
                    </svg>
                  </div>
                  <div class="box__line_2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="229"
                      height="5"
                      viewBox="0 0 229 5"
                    >
                      <line x2="224" transform="translate(2.5 2.5)" />
                    </svg>
                  </div>
                </div>
                <div class="letsTalk__text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="515"
                    height="136"
                    viewBox="0 0 515 136"
                  >
                    <text
                      style="fill: #212121;
                    font-size: 56px;
                    font-family: Montserrat-Light, Montserrat;
                    font-weight: 300;"
                      transform="translate(2 54)"
                    >
                      <tspan x="0" y="0">Tell us more about</tspan>
                      <tspan x="0" y="68">your project!</tspan>
                    </text>
                  </svg>
                </div>
              </div>
            </div>
            <div class="letsTalk__info_text">
              <div class="--letsTalk__info_text">
                <div data-depth="0.2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="530"
                    height="136"
                    viewBox="0 0 530 136"
                  >
                    <text
                      style="fill: #212121;
                    font-size: 28px;
                    font-family: Montserrat-Regular, Montserrat;"
                      transform="translate(0 27)"
                    >
                      <tspan x="0" y="0">
                        Include these points in your message:
                      </tspan>
                      <tspan x="0" y="34">- Your idea</tspan>
                      <tspan x="0" y="68">- Your timeline</tspan>
                      <tspan x="0" y="102">- Your budget</tspan>
                    </text>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store";
import Navbar from "@/components/Navbar/Navbar.vue";
import Background from "@/components/shared/background.vue";
import gsap from "gsap";
import { load } from "recaptcha-v3";

export default {
  name: "LetsTalk",
  components: {
    Navbar,
    Background,
  },
  data: function() {
    return {
      FullName: "",
      Email: "",
      Message: "",
      Attachment: null,
      EmailVerification: false,
    };
  },
  methods: {
    FullNameOnchange: function(e) {
      this.CheckValidation(e, true);
    },
    EmailNameOnchange: function(e) {
      if (this.ValidEmail(e.target.value)) this.EmailVerification = true;
      else this.EmailVerification = false;
      this.CheckValidation(e, this.EmailVerification);
    },
    MessageOnchange: function(e) {
      this.CheckValidation(e, true);
    },
    AttachmentOnchange: function(e) {
      this.Attachment = e.target.files[0];
      this.CheckValidation(e, false, e.target.files[0]);
    },
    CheckValidation: function(obj, extraValid, file = null) {
      if (
        (obj != null && obj.target.value.trim().length > 0 && extraValid) ||
        file != null
      ) {
        obj.target.classList.remove("validation-input-error");
        obj.target.classList.add("validation-input-valid");
        obj.path[1].children[1].classList.remove("fa-info-circle");
        obj.path[1].children[1].classList.add("fa-check-circle");
      } else {
        obj.target.classList.add("validation-input-error");
        obj.target.classList.remove("validation-input-valid");
        obj.path[1].children[1].classList.remove("fa-check-circle");
        obj.path[1].children[1].classList.add("fa-info-circle");
      }
    },
    Submit() {
      var that = this;
      document.querySelectorAll(".btn_lets__talk").forEach((button) => {
        let getVar = (variable) =>
          getComputedStyle(button).getPropertyValue(variable);
        var fullnameInput = document.querySelector("#FullName");
        var emailInput = document.querySelector("#Email");
        var messageInput = document.querySelector("#Message");
        var errorSpan = document.querySelectorAll("#validation-error-span");

        /** FullName Validation */
        that.ValidationField(that.FullName, fullnameInput, errorSpan[0], true);

        /** Eamil Validation */
        that.ValidationField(
          that.Email,
          emailInput,
          errorSpan[1],
          that.EmailVerification
        );

        /** Message Validation */
        that.ValidationField(that.Message, messageInput, errorSpan[2], true);

        /**Submit */
        if (
          !button.classList.contains("active") &&
          that.EmailVerification &&
          that.Message != "" &&
          that.Message != null &&
          that.FullName != "" &&
          that.FullName != null
        ) {
          button.classList.add("active");

          gsap.to(button, {
            keyframes: [
              {
                "--left-wing-first-x": 50,
                "--left-wing-first-y": 100,
                "--right-wing-second-x": 50,
                "--right-wing-second-y": 100,
                duration: 0.2,
                onComplete() {
                  gsap.set(button, {
                    "--left-wing-first-y": 0,
                    "--left-wing-second-x": 40,
                    "--left-wing-second-y": 100,
                    "--left-wing-third-x": 0,
                    "--left-wing-third-y": 100,
                    "--left-body-third-x": 40,
                    "--right-wing-first-x": 50,
                    "--right-wing-first-y": 0,
                    "--right-wing-second-x": 60,
                    "--right-wing-second-y": 100,
                    "--right-wing-third-x": 100,
                    "--right-wing-third-y": 100,
                    "--right-body-third-x": 60,
                  });
                },
              },
              {
                "--left-wing-third-x": 20,
                "--left-wing-third-y": 90,
                "--left-wing-second-y": 90,
                "--left-body-third-y": 90,
                "--right-wing-third-x": 80,
                "--right-wing-third-y": 90,
                "--right-body-third-y": 90,
                "--right-wing-second-y": 90,
                duration: 0.2,
              },
              {
                "--rotate": 50,
                "--left-wing-third-y": 95,
                "--left-wing-third-x": 27,
                "--right-body-third-x": 45,
                "--right-wing-second-x": 45,
                "--right-wing-third-x": 60,
                "--right-wing-third-y": 83,
                duration: 0.25,
              },
              {
                "--rotate": 55,
                "--plane-x": -8,
                "--plane-y": 24,
                duration: 0.4,
              },
              {
                "--rotate": 40,
                "--plane-x": 45,
                "--plane-y": -180,
                "--plane-opacity": 0,
                duration: 0.5,
                onComplete() {
                  that.SubmitLetsTalk(button);
                },
              },
            ],
          });

          gsap.to(button, {
            keyframes: [
              {
                "--text-opacity": 0,
                "--border-radius": 0,
                "--left-wing-background": getVar("--primary-darkest"),
                "--right-wing-background": getVar("--primary-darkest"),
                duration: 0.1,
              },
              {
                "--left-wing-background": getVar("--primary"),
                "--right-wing-background": getVar("--primary"),
                duration: 0.1,
              },
              {
                "--left-body-background": getVar("--primary-dark"),
                "--right-body-background": getVar("--primary-darkest"),
                duration: 0.4,
              },
            ],
          });
        }
      });
    },
    SubmitLetsTalk: function(button) {
      this.$gtag.event("SubmitLetsTalk", { method: "Google" });
      load("6LcDKDAaAAAAAK48DMKX8RHVIoQi912TC4paziQ6").then((recaptcha) => {
        recaptcha.execute("submit").then((token) => {
          store
            .dispatch("SubmitLetsTalk", {
              fullname: this.FullName,
              email: this.Email,
              message: this.Message,
              attachment: this.Attachment,
              grecaptcha_response: token,
            })
            .then((res) => {
              if (res.data == true) {
                button.style.display = "none";
                this.$("#ModalSubmitLetstalk").modal({
                  keyboard: false,
                  show: true,
                  backdrop: "static",
                });
              } else {
                this.ActiveSendButton(button);
                //to implement
                alert("opss .. an error has occurred");
              }
            });
        });
      });
    },
    ActiveSendButton: function(button) {
      setTimeout(() => {
        button.removeAttribute("style");
        gsap.fromTo(
          button,
          {
            opacity: 0,
            y: -8,
          },
          {
            opacity: 1,
            y: 0,
            clearProps: true,
            duration: 0.3,
            onComplete() {
              button.classList.remove("active");
            },
          }
        );
      }, 1000);
    },
    ValidationField: function(value, input, errorSpan, emailVerification) {
      if (value.trim() != "" && value != null && emailVerification) {
        input.classList.remove("validation-input-error");
        errorSpan.classList.remove("fa-info-circle");
        errorSpan.classList.add("fa-check-circle");
      } else {
        input.classList.add("validation-input-error");
        errorSpan.classList.add("fa-info-circle");
        errorSpan.classList.remove("fa-check-circle");
      }
    },
    ValidEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  mounted() {
    this.$destroy();
    var that = this;
    that.$(".btn-back").click(function() {
      that.$(".toggle-side").removeClass("show");
      that.$(".side-menu").removeClass("show");
      that.$(".toggle-side").hide();
      that.$(".side-menu").hide();
      setTimeout(function() {
        that.$(".side-menu").show();
        if (window.innerWidth > 767) {
          that.$(".toggle-side").hide();
        } else {
          that.$(".toggle-side").show();
        }
      }, 0.1);
    });
    new this.$parallax(document.querySelector(".--letsTalk__text"));
    new this.$parallax(document.querySelector(".--letsTalk__info_text"));
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.letstalk___ {
  height: 100vh;
  padding-top: 9em;
  width: 100%;
  position: fixed;
}
.form-content {
  max-width: 100%;
}
.letsTalk__fields {
  width: 60%;
}
.letsTalk__title {
  transform: translate(180px, 10px);
}
.letsTalk__text {
  transform: translate(0px, 150px);
}
.letsTalk__info {
  transform: translate(0px, -10px);
}
.letsTalk__info_box {
  transform: translate(-80px, 10px);
}
.letsTalk__info_text {
  padding-top: 10em;
}
.letsTalk__box_lines {
  display: none;
}
#letsTalk__title__2 {
  display: none;
}
.form-group {
  padding: 0.5em;
}
.field__ {
  padding: 1.4em;
  border: 0.08em solid #707070;
  border-radius: 10px;
}
.field__file {
  color: #212121;
  padding: 0.7em;
  background-color: #ffffff;
  border: 0.1em solid #707070;
  border-radius: 10px;
}
.validation-input-error {
  border-color: red;
}
.validation-input-valid {
  border-color: green;
}
#validation-error-span {
  float: right;
  margin-right: 6px;
  margin-top: -20px;
  position: relative;
  z-index: 2;
}
.fa-check-circle {
  color: green;
}
.fa-info-circle {
  color: red;
}
.modal_btn-send {
  color: #ffffff;
  border-radius: 7px;
  padding: 12px;
  width: 150px;
  text-align: center;
  font-size: 18px;
  font-family: Poppins-Bold, Poppins;
  font-weight: 700;
  text-decoration: none;
  box-shadow: 5px 10px;
  background-color: #707070;
  -webkit-box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
}
.modal__text {
  font-family: Montserrat-Regular, Montserrat;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
}
.modal__title {
  fill: #212121;
  font-size: 30px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: 700;
  text-align: center;
}
.btn_lets__talk {
  --primary: #ffce00;
  --primary-dark: #ffce00;
  --primary-darkest: #ffce00;
  --text: #212121;
  --text-opacity: 1;
  --success: #2b3044;
  --success-scale: 0.2;
  --success-opacity: 0;
  --border-radius: 7;
  --overflow: hidden;
  --rotate: 0;
  --plane-x: 0;
  --plane-y: 0;
  --plane-opacity: 1;
  --left-wing-background: var(--primary);
  --left-wing-first-x: 0;
  --left-wing-first-y: 0;
  --left-wing-second-x: 50;
  --left-wing-second-y: 0;
  --left-wing-third-x: 0;
  --left-wing-third-y: 100;
  --left-body-background: var(--primary);
  --left-body-first-x: 50;
  --left-body-first-y: 0;
  --left-body-second-x: 50;
  --left-body-second-y: 100;
  --left-body-third-x: 0;
  --left-body-third-y: 100;
  --right-wing-background: var(--primary);
  --right-wing-first-x: 50;
  --right-wing-first-y: 0;
  --right-wing-second-x: 100;
  --right-wing-second-y: 0;
  --right-wing-third-x: 100;
  --right-wing-third-y: 100;
  --right-body-background: var(--primary);
  --right-body-first-x: 50;
  --right-body-first-y: 0;
  --right-body-second-x: 50;
  --right-body-second-y: 100;
  --right-body-third-x: 100;
  --right-body-third-y: 100;
  display: block;
  cursor: pointer;
  position: relative;
  width: 150px;
  border: 0;
  padding: 8px 0;
  min-width: 100px;
  text-align: center;
  margin: 0;
  line-height: 24px;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  background: none;
  outline: none;
  color: var(--text);
  transform: rotate(calc(var(--rotate) * 1deg)) translateZ(0);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  .left,
  .right {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: var(--plane-opacity);
    transform: translate(calc(var(--plane-x) * 1px), calc(var(--plane-y) * 1px))
      translateZ(0);
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: calc(var(--border-radius) * 1px);
      transform: translate(var(--x, 0.4%), var(--y, 0)) translateZ(0);
      z-index: var(--z-index, 2);
      background: var(--background, var(--left-wing-background));
      clip-path: polygon(
        calc(var(--first-x, var(--left-wing-first-x)) * 1%)
          calc(var(--first-y, var(--left-wing-first-y)) * 1%),
        calc(var(--second-x, var(--left-wing-second-x)) * 1%)
          calc(var(--second-y, var(--left-wing-second-y)) * 1%),
        calc(var(--third-x, var(--left-wing-third-x)) * 1%)
          calc(var(--third-y, var(--left-wing-third-y)) * 1%)
      );
    }
  }
  .left:after {
    --x: 0;
    --z-index: 1;
    --background: var(--left-body-background);
    --first-x: var(--left-body-first-x);
    --first-y: var(--left-body-first-y);
    --second-x: var(--left-body-second-x);
    --second-y: var(--left-body-second-y);
    --third-x: var(--left-body-third-x);
    --third-y: var(--left-body-third-y);
  }
  .right:before {
    --x: -0.4%;
    --z-index: 2;
    --background: var(--right-wing-background);
    --first-x: var(--right-wing-first-x);
    --first-y: var(--right-wing-first-y);
    --second-x: var(--right-wing-second-x);
    --second-y: var(--right-wing-second-y);
    --third-x: var(--right-wing-third-x);
    --third-y: var(--right-wing-third-y);
  }
  .right:after {
    --x: 0;
    --z-index: 1;
    --background: var(--right-body-background);
    --first-x: var(--right-body-first-x);
    --first-y: var(--right-body-first-y);
    --second-x: var(--right-body-second-x);
    --second-y: var(--right-body-second-y);
    --third-x: var(--right-body-third-x);
    --third-y: var(--right-body-third-y);
  }
  span {
    display: block;
    position: relative;
    z-index: 4;
    opacity: var(--text-opacity);
    &.success {
      z-index: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 8px;
      transform: rotate(calc(var(--rotate) * -1deg)) scale(var(--success-scale))
        translateZ(0);
      opacity: var(--success-opacity);
      color: var(--success);
    }
  }
}
input[type="file"] {
  overflow: auto;
}
@media only screen and (max-width: 1475px) {
  .letsTalk__fields {
    transform: scale(0.9) translate(0px, -30px);
    width: 70%;
  }
  .letsTalk__info {
    transform: scale(0.8) translate(-70px, -80px);
  }
}
@media only screen and (max-width: 1200px) {
  .letsTalk__fields {
    transform: scale(0.75) translate(0px, -65px);
    width: 80%;
  }
  .letsTalk__info {
    transform: scale(0.7) translate(-140px, -105px);
  }
}
@media only screen and (max-width: 992px) {
  .letsTalk__fields {
    transform: scale(0.7) translate(0px, -115px);
    width: 100%;
  }
  .letsTalk__info {
    transform: scale(0.6) translate(-160px, -200px);
  }
}
@media only screen and (max-width: 850px) {
  .letsTalk__info {
    transform: scale(0.55) translate(-190px, -245px);
  }
}
/*responsive mobile*/
@media only screen and (max-width: 767px) {
  .letstalk___ {
    padding-top: 6em;
  }
  .letsTalk__info_box {
    height: 140px;
  }
  .letsTalk__fields_box {
    order: 1;
    justify-content: unset !important;
    padding-top: 3em;
  }
  .letsTalk__fields {
    transform: scale(0.7) translate(0px, 0px);
    width: 500px;
  }
  .letsTalk__info {
    transform: scale(0.5) translate(-115px, -80px);
  }
  .form-group {
    padding: unset;
  }
  #Message {
    height: 130px;
  }
  .letsTalk__title {
    transform: translate(160px, 10px);
  }
  #letsTalk__title__1 {
    display: none;
  }
  #letsTalk__title__2 {
    display: block;
  }
  .letsTalk__text {
    transform: translate(160px, 20px);
  }
  .letsTalk__box_lines {
    display: block;
    transform: translate(155px, 10px);
  }
  .letsTalk__info_text {
    padding-top: 3em;
    transform: translate(160px, 0px);
  }
}

@media only screen and (max-width: 499px) {
  .letsTalk__info_box {
    height: 110px;
  }
  .letsTalk__fields {
    transform: scale(0.8) translate(0px, 0px);
    width: 100%;
  }
  .letsTalk__info {
    transform: scale(0.4) translate(-300px, -110px);
  }
  .modal__text {
    font-size: 20px;
  }
  .modal__title {
    font-size: 25px;
  }
  .modal_btn-send {
    font-size: 16px;
  }
  .letsTalk__info_text {
    transform: translate(220px, 0px);
  }
  .letsTalk__text {
    transform: translate(220px, 20px);
  }
  .letsTalk__title {
    transform: translate(220px, 10px);
  }
  .letsTalk__box_lines {
    transform: translate(215px, 10px);
  }
  .letsTalk__fields_box {
    padding-top: 2.5em;
  }
  .modal__send-icon {
    transform: scale(0.8);
  }
}
@media only screen and (max-height: 630px) {
  .letsTalk__fields_box {
    padding-top: 0em;
  }
  .field__ {
    padding: 1em;
  }
  .letsTalk__fields {
    transform: scale(0.7) translate(-22px, 0px);
  }
  .letsTalk__info {
    transform: scale(0.35) translate(-350px, -150px);
  }
}
@media only screen and (max-width: 400px) {
  .modal__text {
    font-size: 18px;
  }
  .modal__title {
    font-size: 21px;
  }
  .modal_btn-send {
    font-size: 14px;
  }
  .letsTalk__info_text {
    transform: translate(188px, 0px);
  }
  .letsTalk__text {
    transform: translate(188px, 20px);
  }
  .letsTalk__title {
    transform: translate(188px, 10px);
  }
  .letsTalk__box_lines {
    transform: translate(183px, 10px);
  }
}
</style>
